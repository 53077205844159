import * as React from "react"
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import * as styles from "./index.module.scss"

export default function Index({data}) {
    const image = getImage(data.file.childImageSharp)
    return (
      <Layout>
          <main className={styles.main}>
          <h1>Anneleen Pieters</h1>
          <h3>Drawing with pens</h3>
          <p>Hello I am Anneleen and I love to draw things with a pen</p>
          <p>If you like you can check them out &nbsp;&#8658; <Link to={"/portfolio"}>here</Link> &#8656;</p>
          <div className={styles.imageContainer}>
              <GatsbyImage alt={"anneleenpieters"} image={image}/>
          </div>
          </main>
      </Layout>
  )
}

export const query = graphql`
query anneleenImage {
  file(relativePath: {eq: "anneleen.jpg"}) {
     childImageSharp {
        gatsbyImageData
     }
  }
}`